<template>
  <div class="w-100 rounded mr-3">
    <div class="p-3 rounded ml-3 mr-3 mb-3" v-background-3>
      <h5 class="m-0">Documents</h5>
      <p class="m-0">
        <small
          >La liste ci-dessous présente les documents du système de management intégré du groupe accessibles. Ces
          documents sont confidentiels et dédié à Arkadia. Vous ne devez les communiquer sans autorisation.</small
        >
      </p>
      <div class="d-flex align-items-center" v-if="loading">
        <loader class="m-5"></loader>
        <h2 class="m-0">Chargement...</h2>
      </div>
      <div class="d-flex flex-wrap align-items-center" v-if="!loading">
        <div class="btn btn-primary mr-2 mt-2" :class="{ active: filter == 1 && filterText == '' }" @click="filter = 1">
          #Procédures
        </div>
        <div class="btn btn-primary mr-2 mt-2" :class="{ active: filter == 2 && filterText == '' }" @click="filter = 2">
          #Formulaires
        </div>
        <div class="btn btn-primary mr-2 mt-2" :class="{ active: filter == 3 && filterText == '' }" @click="filter = 3">
          #Supports
        </div>
        <div class="btn btn-primary mr-2 mt-2" :class="{ active: filter == 4 && filterText == '' }" @click="filter = 4">
          #Trames
        </div>
        <div class="ml-auto p-1 mt-1 rounded">
          <InputSearch
            text="Rechercher"
            v-model="filterText"
            @reset="filterText = ''"
            modeIOS
            style="min-width: 15rem"
          ></InputSearch>
        </div>
      </div>
    </div>
    <div class="pl-3 pr-3" style="height: 30rem; overflow: auto" v-if="!loading">
      <div v-for="doc in docFiltered" :key="doc.id">
        <documents :doc="doc"></documents>
      </div>
    </div>
  </div>
</template>
<script>
import Documents from "@/components/profil/DocumentsItem.vue";
import InputSearch from "@/components/bases/InputSearch.vue";
import Loader from "@/components/bases/Loader.vue";

export default {
  components: {
    Documents,
    InputSearch,
    Loader,
  },
  data() {
    return {
      loading: false,
      feedback: {},
      lists: {},
      documents: [],
      filter: 1,
      filterText: "",
    };
  },
  computed: {
    docFiltered: function () {
      if (this.filterText == "") return this.documents.filter((doc) => doc.type_id == this.filter);
      return this.documents.filter((doc) => doc.nom.toLowerCase().includes(this.filterText.toLowerCase()));
    },
  },
  methods: {
    getDocuments: function () {
      this.loading = true;
      this.$http
        .get("/my-documents")
        .then((response) => (this.documents = response.data))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>
<style></style>

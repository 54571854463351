<template>
  <div class="m-auto">
    <div class="mb-5 mt-5">
      <img v-if="image" class="rounded mx-auto d-block" style="width: 100%; max-width: 800px" v-bind:src="imageUrl" />
    </div>
    <div class="mb-3">
      <div class="w-100 pl-3 pr-3 pl-lg-5 pr-lg-5">
        <div class="mb-4" v-html="diapo.text"></div>
        <div class="pb-2">
          <checkbox
            id="1"
            v-model="rep.q1"
            :text="diapo.q1"
            :class="{ 'text-success': diapo.r1 && checked, 'text-danger': !diapo.r1 && checked }"
          ></checkbox>
        </div>
        <div class="pb-2">
          <checkbox
            id="2"
            v-model="rep.q2"
            :text="diapo.q2"
            :class="{ 'text-success': diapo.r2 && checked, 'text-danger': !diapo.r2 && checked }"
          ></checkbox>
        </div>
        <div class="pb-2">
          <checkbox
            id="3"
            v-model="rep.q3"
            :text="diapo.q3"
            :class="{ 'text-success': diapo.r3 && checked, 'text-danger': !diapo.r3 && checked }"
          ></checkbox>
        </div>
        <div class="pb-2">
          <checkbox
            id="4"
            v-model="rep.q4"
            :text="diapo.q4"
            :class="{ 'text-success': diapo.r4 && checked, 'text-danger': !diapo.r4 && checked }"
          ></checkbox>
        </div>
        <div class="mt-3 mb-3 text-center alert alert-danger" v-if="errors">
          <div>{{ diapo.correction }}</div>
        </div>
        <div class="text-center alert alert-success mt-3 mb-3" v-if="success">Bravo, les réponses sont justes !</div>
        <div class="text-center mt-3">
          <button class="btn btn-primary" v-if="!checked" @click="$emit('check', rep)">Valider les réponses</button>
          <button class="btn btn-primary" v-if="checked && index !== total - 1" @click="$emit('next')">Suivant</button>
          <button class="btn btn-primary" v-if="checked && index == total - 1" @click="$emit('end')">Terminer</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from "@/components/bases/Checkbox.vue";

export default {
  components: { Checkbox },
  data() {
    return {
      rep: {
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
      },
    };
  },
  props: ["checked", "success", "errors", "diapo", "index", "total", "feedback", "image"],
  computed: {
    imageUrl: function () {
      return "https://api.arkadia-qwark.fr/storage/" + this.image;
    },
  },
  watch: {
    index: function () {
      this.rep = { q1: 0, q2: 0, q3: 0, q4: 0 };
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style></style>
